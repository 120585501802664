import React, { useState, useEffect } from "react";
import styled from "styled-components";
// GLOBAL STYLES
import GlobalStyle from "./GlobalStyle";
// ROUTER
import { Switch, Route, useHistory } from "react-router-dom";
// PAGES
import Home from "./pages/Home";
import Game from "./pages/Game";
import NewGame from "./pages/NewGame";
import Players from "./pages/Players";
import NewPlayer from "./pages/NewPlayer";
import PlayerSelect from "./pages/PlayerSelect";
import Statistic from "./pages/Statistic";
import Winner from "./pages/Winner";
import Online from "./pages/Online";
import OnlineRoom from "./pages/OnlineRoom";
import CookieBanner from "./components/CookieBanner";
// WEBSOCKET
import { io } from "socket.io-client";
import Settings from "./pages/Settings";

function App() {
  // States
  const [allPlayers, setAllPlayers] = useState([]);
  const [settings, setSettings] = useState(null);
  const [gameSettings, setGameSettings] = useState({});
  const [currentGame, setCurrentGame] = useState({});
  const [currentPlayer, setCurrentPlayer] = useState([]);
  const [savedPlayers, setSavedPlayers] = useState(null);
  const [spielstand, setSpielstand] = useState([]); // SCORE
  const [gesamtdauer, setGesamtdauer] = useState(null);
  const history = useHistory();
  const [statistics, setStatistics] = useState(null);
  const [roomId, setRoomId] = useState("");
  const [userId, setUserId] = useState(sessionStorage.getItem("stahlspicker-userId") ?? null);
  const [socket, setSocket] = useState(null);
  const [locationKeys, setLocationKeys] = useState([]);
  const [onlineCheck, setOnlineCheck] = useState(false);
  useEffect(() => {
    const pathname = history.location.pathname;
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          if (pathname === "/newplayer") {
            history.push("/players");
          } else if (pathname === "/playerselection") {
            history.push("/newgame");
          } else if (pathname === "/") {
            history.replace({
              pathname: "/",
              state: { fromHome: true },
            });
          } else {
            history.push("/");
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          if (pathname === "/newplayer") {
            history.push("/players");
          } else if (pathname === "/playerselection") {
            history.push("/newgame");
          } else if (pathname === "/") {
            history.replace({
              pathname: "/",
              state: { fromHome: true },
            });
          } else {
            history.push("/");
          }
        }
      }
    });
  }, [locationKeys, history]);

  useEffect(() => {
    setSettings({
      ...(JSON.parse(localStorage.getItem("stahlspicker-settings")) &&
      localStorage.getItem("stahlspicker-settings") !== "{}"
        ? JSON.parse(localStorage.getItem("stahlspicker-settings"))
        : { playOn: true, correction: true, alignment: true }),
    });
    const storageSavedPlayers = JSON.parse(localStorage.getItem("stahlspicker-savedplayers"));
    storageSavedPlayers ? setSavedPlayers([...storageSavedPlayers]) : setSavedPlayers([]);
    setSocket(io(process.env.REACT_APP_SOCKET));
  }, []);

  useEffect(() => {
    socket?.on("connect", () => {
      if (localStorage.getItem("stahlspicker-userId") === null) {
        const id = socket.id
          .replaceAll(/[^a-zA-Z0-9.]/g, "")
          .substr(0, 8)
          .toUpperCase();
        setUserId(id);
        localStorage.setItem("stahlspicker-userId", id);
      } else {
        setUserId(localStorage.getItem("stahlspicker-userId"));
      }
    });
  }, [socket]);

  useEffect(() => {
    localStorage.setItem("stahlspicker-settings", JSON.stringify(settings));
    localStorage.setItem("stahlspicker-savedplayers", JSON.stringify(savedPlayers));
  }, [settings, savedPlayers]);

  // const DarkmodeSwitch = () => {
  //   return (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="46.793"
  //       height="46.793"
  //       viewBox="0 0 46.793 46.793"
  //       className="darkmode"
  //       onClick={() => {
  //         settings &&
  //           setSettings({ ...settings, darkmode: !settings.darkmode });
  //       }}
  //     >
  //       <path
  //         id="Vereinigungsmenge_51"
  //         data-name="Vereinigungsmenge 51"
  //         d="M1835.788-5754.815v-4.767a1.638,1.638,0,0,1,1.608-1.608,1.638,1.638,0,0,1,1.61,1.608v4.767a1.638,1.638,0,0,1-1.61,1.608A1.638,1.638,0,0,1,1835.788-5754.815Zm15.907-5.262-3.4-3.4a1.571,1.571,0,0,1,0-2.229,1.568,1.568,0,0,1,2.229,0l3.4,3.4a1.57,1.57,0,0,1,0,2.229,1.57,1.57,0,0,1-1.115.464A1.569,1.569,0,0,1,1851.7-5760.077Zm-30.887,0a1.57,1.57,0,0,1,0-2.229l3.4-3.4a1.566,1.566,0,0,1,2.227,0,1.568,1.568,0,0,1,0,2.229l-3.4,3.4a1.569,1.569,0,0,1-1.114.464A1.57,1.57,0,0,1,1820.808-5760.077Zm8.294-8.231a11.8,11.8,0,0,1-3.466-8.3,11.8,11.8,0,0,1,3.466-8.294,11.546,11.546,0,0,1,8.294-3.466,11.8,11.8,0,0,1,8.294,3.466,11.542,11.542,0,0,1,3.467,8.294,11.8,11.8,0,0,1-3.467,8.3,11.546,11.546,0,0,1-8.294,3.466A11.8,11.8,0,0,1,1829.1-5768.309Zm2.229-14.361a8.564,8.564,0,0,0-2.476,6.065,8.562,8.562,0,0,0,2.476,6.067,8.53,8.53,0,0,0,5.363,2.448v-17.029A8.535,8.535,0,0,0,1831.331-5782.669Zm23.087,7.675a1.639,1.639,0,0,1-1.61-1.608,1.639,1.639,0,0,1,1.61-1.61h4.766a1.638,1.638,0,0,1,1.61,1.61,1.638,1.638,0,0,1-1.61,1.608Zm-38.809,0A1.6,1.6,0,0,1,1814-5776.6a1.638,1.638,0,0,1,1.609-1.61h4.767a1.638,1.638,0,0,1,1.608,1.61,1.638,1.638,0,0,1-1.608,1.608Zm32.744-12.5a1.57,1.57,0,0,1,0-2.229l3.4-3.4a1.57,1.57,0,0,1,2.229,0,1.567,1.567,0,0,1,0,2.229l-3.4,3.4a1.611,1.611,0,0,1-1.138.464A1.454,1.454,0,0,1,1848.353-5787.5Zm-24.14,0-3.4-3.4a1.57,1.57,0,0,1,0-2.229,1.57,1.57,0,0,1,2.229,0l3.4,3.4a1.568,1.568,0,0,1,0,2.229,1.564,1.564,0,0,1-1.114.464A1.566,1.566,0,0,1,1824.213-5787.5Zm11.575-6.127v-4.766a1.638,1.638,0,0,1,1.608-1.61,1.638,1.638,0,0,1,1.61,1.61v4.766a1.639,1.639,0,0,1-1.61,1.61A1.639,1.639,0,0,1,1835.788-5793.625Z"
  //         transform="translate(-1814.001 5800)"
  //         fill={settings && !settings.darkmode ? "#fff" : "#000"}
  //       />
  //     </svg>
  //   );
  // };

  return (
    <>
      {settings && savedPlayers && (
        <StyledApp
          style={{ overflow: ["/game", "/onlinegame"]?.includes(history?.location?.pathname) ? "hidden" : "auto" }}
          className={settings && !settings.darkmode ? "dark-bg darkSelection" : "bright-bg lightSelection"}
        >
          <GlobalStyle />

          <Switch>
            <Route path="/" exact>
              {/* <DarkmodeSwitch /> */}
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <Home
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                socket={socket}
                userId={userId}
                setRoomId={setRoomId}
              />
            </Route>

            <Route path="/settings" exact>
              {/* <DarkmodeSwitch /> */}
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <Settings setSettings={setSettings} settings={settings} />
            </Route>

            <Route path="/players" exact>
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <Players
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                savedPlayers={savedPlayers}
                setSavedPlayers={setSavedPlayers}
              />
            </Route>
            <Route path="/newplayer" exact>
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <NewPlayer
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                savedPlayers={savedPlayers}
                setSavedPlayers={setSavedPlayers}
              />
            </Route>

            <Route path="/newgame" exact>
              {/* <DarkmodeSwitch /> */}
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <NewGame
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
              />
            </Route>

            <Route path="/playerselection" exact>
              {/* <DarkmodeSwitch /> */}
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <PlayerSelect
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                savedPlayers={savedPlayers}
                userId={userId}
                onlineCheck={onlineCheck}
                setOnlineCheck={setOnlineCheck}
              />
            </Route>

            <Route path="/game" exact>
              <Game
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                spielstand={spielstand}
                setSpielstand={setSpielstand}
                setGesamtdauer={setGesamtdauer}
                statistics={statistics}
                setStatistics={setStatistics}
              />
            </Route>

            <Route path="/onlinegame" exact>
              {socket && userId && (
                <Game
                  setCurrentPlayer={setCurrentPlayer}
                  currentPlayer={currentPlayer}
                  setCurrentGame={setCurrentGame}
                  currentGame={currentGame}
                  setGameSettings={setGameSettings}
                  gameSettings={gameSettings}
                  setSettings={setSettings}
                  settings={settings}
                  allPlayers={allPlayers}
                  setAllPlayers={setAllPlayers}
                  spielstand={spielstand}
                  setSpielstand={setSpielstand}
                  setGesamtdauer={setGesamtdauer}
                  statistics={statistics}
                  setStatistics={setStatistics}
                  userId={userId}
                  socket={socket}
                  online
                />
              )}
            </Route>

            <Route path="/online" exact>
              {!roomId && <OnlineRoom settings={settings} roomId={roomId} setRoomId={setRoomId} />}
              {roomId && socket && userId && (
                <Online
                  setCurrentPlayer={setCurrentPlayer}
                  currentPlayer={currentPlayer}
                  setCurrentGame={setCurrentGame}
                  currentGame={currentGame}
                  setGameSettings={setGameSettings}
                  gameSettings={gameSettings}
                  setSettings={setSettings}
                  settings={settings}
                  allPlayers={allPlayers}
                  setAllPlayers={setAllPlayers}
                  spielstand={spielstand}
                  setSpielstand={setSpielstand}
                  setGesamtdauer={setGesamtdauer}
                  socket={socket}
                  userId={userId}
                  roomId={roomId}
                />
              )}
            </Route>

            <Route path="/winner" exact>
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              {/* <DarkmodeSwitch /> */}
              <Winner
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                spielstand={spielstand}
                setSpielstand={setSpielstand}
                gesamtdauer={gesamtdauer}
              />
            </Route>

            <Route path="/onlinewinner" exact>
              {/* <DarkmodeSwitch /> */}
              {socket && userId && (
                <Winner
                  setCurrentPlayer={setCurrentPlayer}
                  currentPlayer={currentPlayer}
                  setCurrentGame={setCurrentGame}
                  currentGame={currentGame}
                  setGameSettings={setGameSettings}
                  gameSettings={gameSettings}
                  setSettings={setSettings}
                  settings={settings}
                  allPlayers={allPlayers}
                  setAllPlayers={setAllPlayers}
                  spielstand={spielstand}
                  setSpielstand={setSpielstand}
                  gesamtdauer={gesamtdauer}
                  userId={userId}
                  socket={socket}
                  online
                />
              )}
            </Route>

            <Route path="/statistic" exact>
              {/* <CookieBanner darkmode={!settings.darkmode} /> */}
              <Statistic
                setCurrentPlayer={setCurrentPlayer}
                currentPlayer={currentPlayer}
                setCurrentGame={setCurrentGame}
                currentGame={currentGame}
                setGameSettings={setGameSettings}
                gameSettings={gameSettings}
                setSettings={setSettings}
                settings={settings}
                allPlayers={allPlayers}
                setAllPlayers={setAllPlayers}
                spielstand={spielstand}
                setSpielstand={setSpielstand}
                gesamtdauer={gesamtdauer}
                statistics={statistics}
                setStatistics={setStatistics}
              />
            </Route>
          </Switch>
        </StyledApp>
      )}
    </>
  );
}

const StyledApp = styled.div`
  width: 100vw;
  transition: background 0.2s ease;
  height: 100vh;

  .darkmode {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
    z-index: 100;
  }
`;

export default App;
